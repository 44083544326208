// ----------------------------------------------------------------------
// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.
// ----------------------------------------------------------------------
var fr = {
    demo: {
        title: "France",
        introduction: "Le Lorem Ipsum est simplement du faux texte employ\u00E9 dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les ann\u00E9es 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour r\u00E9aliser un livre sp\u00E9cimen de polices de texte. Il n'a pas fait que survivre cinq si\u00E8cles, mais s'est aussi adapt\u00E9 \u00E0 la bureautique informatique, sans que son contenu n'en soit modifi\u00E9. Il a \u00E9t\u00E9 popularis\u00E9 dans les ann\u00E9es 1960 gr\u00E2ce \u00E0 la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus r\u00E9cemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.",
    },
    docs: {
        hi: "Bonjour",
        description: "Besoin d'aide? \n Consultez notre documentation.",
        documentation: "documentation",
    },
    app: "application",
    user: "utilisateur",
    list: "liste",
    edit: "\u00E9diter",
    shop: "boutique",
    blog: "blog",
    post: "poste",
    mail: "e-mail",
    chat: "discuter",
    cards: "cartes",
    posts: "des postes",
    create: "cr\u00E9er",
    kanban: "kanban",
    general: "g\u00E9n\u00E9ral",
    banking: "bancaire",
    booking: "r\u00E9servation",
    profile: "profil",
    account: "compte",
    product: "produit",
    invoice: "facture",
    details: "d\u00E9tail",
    checkout: "v\u00E9rifier",
    calendar: "calendrier",
    analytics: {
        title: "analytique",
        chart_stats_client_response: "Graphique d'analyse des statistiques de réponse des clients",
        chart_stats_client_response_by_month: "Graphique de la réponse des statistiques des clients par mois"
    },
    ecommerce: "e-commerce",
    management: "management",
    menu_level: "niveau menu",
    menu_level_2a: "niveau menu 2a",
    menu_level_2b: "niveau menu 2b",
    menu_level_3a: "niveau menu 3a",
    menu_level_3b: "niveau menu 3b",
    menu_level_4a: "niveau menu 4a",
    menu_level_4b: "niveau menu 4b",
    item_disabled: "\u00E9l\u00E9ment d\u00E9sactiv\u00E9",
    item_label: "\u00E9tiquette de l'article",
    item_caption: "l\u00E9gende de l'article",
    item_external_link: "lien externe de l'article",
    description: "la description",
    other_cases: "autres cas",
    item_by_roles: "\u00E9l\u00E9ment par r\u00F4les",
    only_admin_can_see_this_item: "seul l'administrateur peut voir cet \u00E9l\u00E9ment",
    codes: "Codes de G\u00E9n\u00E9ration de Paiement Quotidien",
    folders: "dossiers",
    delete: "Supprimer",
    Offices: 'Bureaux'
};
export default fr;
